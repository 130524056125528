<template>
  <el-col :span="24" class="mb-4">
    <div class="commentCard">
      <el-row>
        <el-col :span="24">
          <span>{{
            $moment(item ? item.CreatedDateTime : null)
              .format("LL")
          }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <h4>{{ item.ProductType }}</h4>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16">
          <el-row>
            <el-col :span="8">
              <img
                class="blur"
                :src="
                  item.Supplier.Image
                    ? item.Supplier.Image
                    : require('../../assets/images/profile/user_Profile.svg')
                "
                width="150"
                alt=""
              />
            </el-col>
            <el-col :span="16" class="py-2 px-2 d-flex flex-column">
              <div class="d-flex align-items-center">
                <span style="margin-right: 5px">
                  {{
                    item.Supplier.FirstName.substring(0, 1) +
                    Array(item.Supplier.FirstName.length - 1)
                      .fill("*")
                      .join("")
                  }}
                  {{
                    item.Supplier.LastName.substring(0, 1) +
                    Array(item.Supplier.LastName.length - 1)
                      .fill("*")
                      .join("")
                  }}
                </span>
                <img
                  class="ml-2 px-1"
                  src="../../assets/icons/nkicon/star.svg"
                  alt=""
                />
                {{ item.Rate.Avg }}
              </div>
              <span> {{ item.Rate.Comment }} </span>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <div class="d-flex flex-column">
            <div class="evaluationSection">
              <span> İletişim </span>
              <div>
                <img
                  v-for="i in item.Rate.Contact"
                  src="../../assets/icons/nkicon/star.svg"
                  alt=""
                  :key="i"
                  style="margin-right: 5px"
                />
              </div>
            </div>
            <div class="evaluationSection mt-3">
              <span> Zamanlama </span>
              <div>
                <img
                  v-for="i in item.Rate.Timing"
                  src="../../assets/icons/nkicon/star.svg"
                  alt=""
                  :key="i"
                  style="margin-right: 5px"
                />
              </div>
            </div>
            <div class="evaluationSection mt-3">
              <span> Aynı Nakliyeciyle Çalışmak İsterim</span>
              <div>
                <img
                  v-for="i in item.Rate.LiveTracking"
                  src="../../assets/icons/nkicon/star.svg"
                  alt=""
                  :key="i"
                  style="margin-right: 5px"
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-col>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    user() {
      return this.$store.getters["userState"];
    },
  },
};
</script>

<style>
.commentCard {
  width: 100%;
  background-color: #4d668a;
  border-radius: 16px;
  padding: 10px 20px;
}
.photo {
  width: 90px;
  height: 90px;
  background-color: white;
}
.evaluationSection {
  background-color: #2c3f59;
  border-radius: 16px;
  padding: 10px 20px;
}
@media (max-width: 767.9px) {
  .evaluationSection {
    margin-bottom: 15px;
  }
}
</style>